import { Home } from "@mui/icons-material";
import { darken, lighten } from "@mui/material";
import { indigo, purple } from "@mui/material/colors";
import { LayoutPaletteColorOptions, LayoutProvider, LayoutRoute, GetRoutesParams } from "wcz-layout";
import packageJson from "../package.json";
import { HomePage } from "./pages/HomePage";
import { LayoutLocalizationProvider } from "./providers/LayoutLocalizationProvider";
import SurveyPage from "./pages/SurveyPage";

export const App: React.FC = () => {

    const getRoutes = ({ t }: GetRoutesParams): LayoutRoute[] => [
        {
            element: <LayoutLocalizationProvider />, children: [
                { path: "/", element: <HomePage />, icon: <Home fontSize="large" />, title: "Training Feedbacks", authenticated: false },
                { path: "/survey", element: <SurveyPage />, title: "Training Feedbacks", authenticated: false },
            ]
        }
    ];

    const colors: LayoutPaletteColorOptions = {
        primary: {
            main: indigo[500],
            light: darken(indigo[500], 0.1),
            dark: lighten(indigo[500], 0.1),
        },
        secondary: {
            main: purple[500],
            light: darken(purple[500], 0.1),
            dark: lighten(purple[500], 0.1),
        }
    };

    return <LayoutProvider getRoutes={getRoutes} colors={colors} appVersion={packageJson.version} />;
};