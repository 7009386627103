import { Flag } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext, ChipInputCell, GridToolbar, TableContainer, newGuid } from "wcz-layout";
import Event from "../models/Event";
import { useGetEvents } from "../queries/EventQueries";

export const HomePage: React.FC = () => {
    const { t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data = [], isFetching } = useGetEvents();

    const redirect = useCallback((row: Event) => () => navigate(`/survey?id=${row.Id}&subid=${row.SubId}`), []);

    const getStatusColor = useCallback((value: string) => {
        switch (value) {
            case "Finished": return "success";
            case "Open": return "success";
            default: return "primary";
        }
    }, []);

    const columns = useMemo(() => [
        { field: "Start", headerName: t("Time"), width: 200 },
        {
            field: "Title", headerName: t("Title"), width: 400,
            renderCell: ({ row, value }) => <Typography variant="body2" sx={{ color: "primary.main", cursor: "pointer" }} onClick={redirect(row)}>{value}</Typography>
        },
        { field: "Location", headerName: t("Location"), width: 250, },
        { field: "Trainer", headerName: t("Trainer"), width: 250, },
        {
            field: "Status", headerName: t("Status"), width: 150, renderCell: params => <ChipInputCell params={params} slotProps={{
                icon: <Flag />,
                color: getStatusColor(params.value)
            }} />
        },

    ] as GridColDef[], [i18n.resolvedLanguage]);

    return (
        <TableContainer>
            <DataGridPremium
                columns={columns}
                rows={data}
                getRowId={row => newGuid()}
                loading={isFetching}
                slots={{ toolbar: GridToolbar }}
            />
        </TableContainer>
    );
};