import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { Error, fetchPost } from "wcz-layout";
import CreateFeedbackDto from "../models/CreateFeedbackDto";
import { apiUrl } from "../utils/BaseUrl";

const route: string = "feedbacks";

export const useCreateFeedback = (options?: Omit<UseMutationOptions<CreateFeedbackDto, Error, CreateFeedbackDto>, "mutationFn" | "onMutate" | "onSettled">) => {
    return useMutation<CreateFeedbackDto, Error, CreateFeedbackDto>({
        ...options,
        mutationFn: model => fetchPost(`${apiUrl}/api/${route}`, model)
    });
};