import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchGet } from "wcz-layout";
import Event from "../models/Event";
import { apiUrl } from "../utils/BaseUrl";

const route: string = "events";

export const useGetEvents = (options?: Omit<DefinedInitialDataOptions<Event[], Error, Event[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Event[], Error, Event[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${apiUrl}/api/${route}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};